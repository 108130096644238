import { connect, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { setSnackbar } from 'redux/index';
import { setInviteUser } from 'redux/reducers';
import { FormSectionHeader, TextFieldRow } from 'components';
import InviteAssignment from './InviteAssignment';
import styles from '../sendInvite.module.css';

function InviteForm(props) {
	const { type, managerMap, branchMap, managerList } = props;
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [manager, setManager] = useState('');
	const [branch, setBranch] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		const user = {
			email: email,
			firstName: firstName,
			lastName: lastName,
			branchId: branch,
			managerAgencyUserId: manager,
		};

		dispatch(setInviteUser(user));
	}, [email, firstName, lastName, manager, branch]);

	const selectManager = (e) => {
		const { value } = e.target;
		let index = managerList.findIndex((val) => {
			return val.id === e.target.value;
		});
		setManager(value);
		setBranch(managerList[index].branchId);
	};

	const selectBranch = (e) => {
		setBranch(e.target.value);
	};

	return (
		<div className={styles.inviteFormContainer}>
			<>
				{['socialWorker', 'mentor'].includes(type) && managerMap ? (
					<InviteAssignment
						label='Manager'
						value={manager}
						onSelect={selectManager}
						listMap={managerMap}
					/>
				) : null}
				{type === 'manager' ? (
					<InviteAssignment
						label='Branch'
						value={branch}
						onSelect={selectBranch}
						listMap={branchMap}
					/>
				) : null}
				{type ? <FormSectionHeader /> : null}
				<TextFieldRow
					required={true}
					name='Email'
					label='Email'
					margin='normal'
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextFieldRow
					name='First Name'
					label='First Name'
					variant='outlined'
					required={true}
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
				/>
				<TextFieldRow
					name='Last Name'
					label='Last Name'
					variant='outlined'
					required={true}
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
				/>
			</>
		</div>
	);
}

export default connect(null, { setSnackbar })(InviteForm);

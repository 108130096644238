import { MenuItem } from '@material-ui/core';
import { TextFieldRow } from 'components';

export const deleteReasons = {
	courtRequest: 'Court Request',
	legalReasons: 'Legal Reasons',
	userError: 'User Error',
};

export default function CSWDeleteDropdown({ value, onChange }) {
	const deleteObjectMap = () => {
		let array = [];
		for (let [key, value] of Object.entries(deleteReasons)) {
			array.push({ value: key, label: value });
		}
		return array;
	};

	const deleteReasonsMap = deleteObjectMap().map((val) => {
		return (
			<MenuItem key={val.value} value={val.value}>
				{val.label}
			</MenuItem>
		);
	});

	return (
		<div style={{ width: '90%' }}>
			<TextFieldRow
				value={value}
				label='Reason for deleting'
				name='cswDeleteReason'
				onChange={onChange}
				select>
				{deleteReasonsMap}
			</TextFieldRow>
		</div>
	);
}
